import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './PositionsSection.css';
import AddJobPopup from './AddJobPopup';
import { getDoc, getFirestore, doc } from 'firebase/firestore/lite';
import { app } from './index';
import { useAuth } from './authContext';

const PositionsSection = ({ jobKeys, handleButtonClick, label }) => {
  const [positions, setPositions] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control the popup
  const [applicantsCount, setApplicantsCount] = useState({});
  const [editData, setEditData] = useState(null); // State to hold data for editing
  const { currentUser } = useAuth();
  const history = useHistory();

  const handleCreatePosition = () => {
    setEditData(null); // Clear edit data when creating a new position
    setIsPopupOpen(true); // Show the popup when the button is clicked
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false); // Hide the popup when it's closed
  };

  const handleAddJob = (jobTitle, jobDescription, customQuestions, personalityTraits) => {
    const newJob = { title: jobTitle, createdDate: new Date().toLocaleDateString(), applicants: 0, starred: 0 };
    setPositions([...positions, newJob]);
    setIsPopupOpen(false); // Close the popup after adding the job
  };

  const fetchCreatedAt = async (key) => {
    const db = getFirestore(app);
    const jobDocRef = doc(db, 'businesses', currentUser.uid, key, 'jobDescription');
    const jobDoc = await getDoc(jobDocRef);
    if (jobDoc.exists()) {
      const jobData = jobDoc.data();
      return jobData.createdAt || "N/A";
    }
    return "N/A";
  };

  const fetchApplicantsCount = async (key) => {
    const db = getFirestore(app);
    const jobDocRef = doc(db, 'businesses', currentUser.uid, key, 'interviewees');
    const jobDoc = await getDoc(jobDocRef);
    let applicantsCount = 0;
    let starredCount = 0;
    if (jobDoc.exists()) {
      const intervieweesData = jobDoc.data();
      applicantsCount = Object.keys(intervieweesData).length;
      Object.values(intervieweesData).forEach(interviewee => {
        if (interviewee.starred === true) {
          starredCount++;
        }
      });
    }
    return { applicantsCount, starredCount };
  };

    // Updated function to only remove the position from the local state (frontend)
    const handleDeletePosition = async (index) => {
      const confirmed = window.confirm('Deleting a position is irreversible, and data inside will be lost. Please select ok to delete.');
      if (confirmed) {
        // If confirmed, remove the position from the local state (UI only)
        const updatedPositions = positions.filter((_, i) => i !== index);
        setPositions(updatedPositions); // Update the state to reflect UI deletion
        alert('The selected Position has been deleted.');
      } else {
        // If the user cancels, log the cancellation
        console.log('Deletion canceled');
      }
    };

  const fetchJobDetails = async (key) => {
    const db = getFirestore(app);
    const jobDocRef = doc(db, 'businesses', currentUser.uid, key, 'jobDescription');
    const jobDoc = await getDoc(jobDocRef);
    if (jobDoc.exists()) {
      return jobDoc.data();
    }
    return null;
  };

  const fetchJobTitle = async (key) => {
    const jobDetails = await fetchJobDetails(key);
    return jobDetails ? jobDetails.jobTitle : "Untitled Job";
  };

  useEffect(() => {
    const fetchJobData = async () => {
      const updatedPositions = await Promise.all(jobKeys.map(async (key) => {
        // Fetch jobTitle from jobDescription
        const title = await fetchJobTitle(key);

        // Fetch createdAt from jobDescription
        const createdAt = await fetchCreatedAt(key);

        // Fetch applicants count and starred count
        const { applicantsCount, starredCount } = await fetchApplicantsCount(key);

        // Fetch generatedLink from jobDescription
        const jobDetails = await fetchJobDetails(key);
        const generatedLink = jobDetails ? jobDetails.generatedLink : '';

        return { key, title, createdAt, applicants: applicantsCount, starred: starredCount, generatedLink };
      }));

      // Reverse the order of positions
      setPositions(updatedPositions.reverse());
    };

    fetchJobData();
  }, [jobKeys]);

  const handleEditPosition = async (index) => {
    const selectedJobKey = positions[index].key;
    const jobDetails = await fetchJobDetails(selectedJobKey);
    if (jobDetails) {
      setEditData({
        jobKey: selectedJobKey,
        jobTitle: jobDetails.jobTitle,
        jobDescription: jobDetails.jobDescription,
        customQuestions: jobDetails.customQuestions || [],
        aiQuestions: jobDetails.aiQuestions, // Populate AI questions based on the number stored
        personalityTraits: Object.entries(jobDetails.personalityTraits || {}).map(([skill, weighting]) => ({
          skill,
          weighting,
        })),
        timeLimit: jobDetails.timeLimit || '1:30',
        requireCamera: jobDetails.requireCamera !== undefined ? jobDetails.requireCamera : true,
        requireResume: jobDetails.requireResume !== undefined ? jobDetails.requireResume : true,
      });
      setIsPopupOpen(true);
    }
  };

  const handleRowClick = (index) => {
    const selectedJobKey = positions[index].key;
    console.log(selectedJobKey, "clicked");
    console.log(label);
    const label2 = (label === 'resume screener' ? 'resume screener' : null);
    console.log(label2);
    handleButtonClick('Candidates', selectedJobKey, label2);
  };

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      alert('Interview link copied to clipboard');
    }).catch((error) => {
      console.error('Error copying link: ', error);
    });
  };

  return (
    <div className="positions-container">
      <div className="create-interview-container">
        <div className="create-interview">
          <h2>Create a new {label === 'resume screener' ? 'Resume Screener' : 'Interview'}</h2>
          <p>Open a new position to start sending resume screening links</p>
          <button className="create-new-interview" onClick={handleCreatePosition}>Create new position</button>
        </div>
      </div>

      <div className="positions-list-container">
        <div className="positions-list">
          <h2>Your {label === 'resume screener' ? 'Resume Screening' : ''} Positions</h2>
          
          {positions.length > 0 ? (
            positions.map((position, index) => (
              <div className="position-item" key={index} >
                <div className="position-details">
                  <p className="position-title" onClick={() => handleRowClick(index)} style={{ cursor: 'pointer' }}>{position.title}</p>
                  <p className="position-meta">Created {position.createdAt} · {position.applicants} applicants · {position.starred} starred</p>
                </div>
                <div className="position-actions">
                  <button className="edit-button" onClick={() => handleCopyLink(position.generatedLink)}>Copy Interview Link</button>
                  <button className="edit-button" onClick={() => handleEditPosition(index)}>Edit</button>
                  <i className="fas fa-trash trash-icon" onClick={() => handleDeletePosition(index)}></i> {/* Trash icon */}
                </div>
              </div>
            ))
          ) : (
            <p className="no-positions">No Active Positions Currently</p>
          )}
        </div>
      </div>

      <AddJobPopup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        onSubmit={handleAddJob}
        editData={editData}
        existingJobKeys={jobKeys}
        label={label == "resume screener" ? label : undefined}
      />
    </div>
  );
};

export default PositionsSection;