import React, { useState, useEffect } from 'react';
import { getFirestore, doc, updateDoc, getDoc, arrayUnion } from 'firebase/firestore/lite';
import { useHistory } from 'react-router-dom';
import './ProfilePopup.css';
import { app } from './index';

const ProfilePopup = ({ name, company, email, onClose, currentUser }) => {
  const [editedName, setEditedName] = useState(name);
  const [editedCompany, setEditedCompany] = useState(company);
  const [editedEmail, setEditedEmail] = useState(email);
  const [stripePayment, setStripePayment] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setEditedName(name);
    setEditedCompany(company);
    setEditedEmail(email);

    const fetchStripePaymentStatus = async () => {
      if (!currentUser) return;
      const db = getFirestore(app);
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setStripePayment(userData.stripePayment || false);
      }
    };

    fetchStripePaymentStatus();
  }, [name, company, email, currentUser]);

  const handleInputChange = (field, value) => {
    setIsChanged(true);
    if (field === 'name') setEditedName(value);
    if (field === 'company') setEditedCompany(value);
    if (field === 'email') setEditedEmail(value);
  };

  const handleSave = async () => {
    if (!isChanged || !currentUser) return;

    const db = getFirestore(app);
    const userDocRef = doc(db, 'businesses', currentUser.uid);

    try {
      const updates = {};
      if (editedName !== name) updates.name = editedName;
      if (editedCompany !== company) updates.company = editedCompany;
      if (editedEmail !== email) updates.email = editedEmail;

      if (Object.keys(updates).length > 0) {
        await updateDoc(userDocRef, updates);
        setIsChanged(false); // Reset the change state
        onClose(); // Close the popup after saving
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleCancelMembership = async () => {
    if (!currentUser) return;
  
    const db = getFirestore(app);
    const userDocRef = doc(db, 'businesses', currentUser.uid);
    const cancelledMembershipsRef = doc(db, 'cancelledMemberships', 'businessIDs');
  
    try {
      await updateDoc(userDocRef, { stripePayment: false });
  
      await updateDoc(cancelledMembershipsRef, {
        [currentUser.uid]: true,
      });
  
      history.push('/cancel-membership');
    } catch (error) {
      console.error('Error cancelling membership:', error);
    }
  };
  

  return (
    <div className="profile-popup">
      <div className="profile-popup-content">
        {showCancelConfirmation ? (
          <>
            <h2>Cancel Membership</h2>
            <p>
              Are you sure you want to cancel your membership? Once you proceed,
              your access will be revoked.
            </p>
            <div className="profile-buttons">
              <button
                className="cancel-button"
                onClick={() => setShowCancelConfirmation(false)}
              >
                Go Back
              </button>
              <button className="proceed-button" onClick={handleCancelMembership}>
                Proceed
              </button>
            </div>
          </>
        ) : (
          <>
            <h2>Profile</h2>
            <div className="profile-field">
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                type="text"
                value={editedName}
                onChange={(e) => handleInputChange('name', e.target.value)}
              />
            </div>
            <div className="profile-field">
              <label htmlFor="company">Company:</label>
              <input
                id="company"
                type="text"
                value={editedCompany}
                onChange={(e) => handleInputChange('company', e.target.value)}
              />
            </div>
            <div className="profile-field">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                type="text"
                value={editedEmail}
                onChange={(e) => handleInputChange('email', e.target.value)}
              />
            </div>
            {stripePayment && (
              <button
                className="cancel-membership-button"
                onClick={() => setShowCancelConfirmation(true)}
              >
                Cancel Membership
              </button>
            )}
            <div className="profile-buttons">
              <button
                onClick={handleSave}
                className={`save-button ${isChanged ? 'enabled' : 'disabled'}`}
                disabled={!isChanged}
              >
                Save
              </button>
              <button onClick={onClose} className="close-button">
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfilePopup;