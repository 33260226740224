import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './authContext';
import './candidateInfo.css';

const CandidateInfo = ({ selectedCandidate, videos, overallScore, resumeURL, jobKey, label2=null }) => {
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(true);
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoPopupVisible, setIsVideoPopupVisible] = useState(false);
  const { currentUser } = useAuth();

  const candidateInfoRef = useRef(null); // For clicking outside
  const [isVisible, setIsVisible] = useState(true);

  const toggleFeedbackVisibility = () => {
    setIsFeedbackVisible(!isFeedbackVisible);
  };

  const handleTraitClick = (trait) => {
    setSelectedTrait(selectedTrait === trait ? null : trait);
  };

  const handlePlayClick = (index) => {
    if (label2 !== 'resume screener') {
      const videoIndex = Math.floor(index / 2); // Adjust logic as needed
      if (videos && videos[videoIndex]) {
        setVideoUrl(videos[videoIndex]);
        setIsVideoPopupVisible(true);
      }
    }
  };

  const closeVideoPopup = () => {
    setIsVideoPopupVisible(false);
    setVideoUrl(null);
  };

  const handleClickOutside = (event) => {
    // If popup is open, close it if outside is clicked
    if (isVideoPopupVisible) {
      closeVideoPopup();
      return;
    }
    // Otherwise, if click is outside the sidebar, hide the sidebar
    if (candidateInfoRef.current && !candidateInfoRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  // Reset visibility whenever a new candidate is selected
  useEffect(() => {
    setIsVisible(true);
  }, [selectedCandidate]);

  // Add/remove outside-click listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVideoPopupVisible]);

  // Scores
  const scores =
    selectedCandidate && selectedCandidate.scores ? selectedCandidate.scores : {};
  const traits = scores ? Object.keys(scores) : [];

  const calculateOverallScore = () => {
    const scoreValues = Object.values(scores);
    const totalScore = scoreValues.reduce((total, score) => total + score, 0);
    return Math.round(totalScore / scoreValues.length) || 0;
  };

  const overall = calculateOverallScore();

  // Copy link
  const { currentUser: user } = useAuth(); // same as currentUser
  const copyLink = () => {
    if (selectedCandidate && selectedCandidate.uid && user) {
      let link = `https://safirahiring.com/candidate-result/${user.uid}/${selectedCandidate.uid}/${jobKey}`;
      if(label2 == 'resume screener'){
        link += '/016310'
      }
      navigator.clipboard
        .writeText(link)
        .then(() => console.log('Link copied to clipboard!'))
        .catch((err) => console.error('Failed to copy link: ', err));
    }
  };

  // Show resume
  const showResumes = () => {
    if (selectedCandidate && selectedCandidate.resumeURL) {
      window.open(selectedCandidate.resumeURL, '_blank');
    } else {
      console.log('No resume URL available');
    }
  };

  // If hidden or no candidate, return nothing
  if (!isVisible || !selectedCandidate) return null;

  // Check if candidate has valid scores
  const shouldShowCandidateInfo =
    selectedCandidate && scores && Object.keys(scores).length > 0;

  // Some trait colors
  const traitColors = [
    '#D1DCF3',
    '#FFD07A',
    '#CEED89',
    '#FDCFE9',
    '#FF8440',
    '#94FFFF',
  ];

  function parseQA(questionAnswers) {
    // Split by newlines and remove any empty lines
    const lines = questionAnswers.split('\n').filter(line => line.trim() !== '');
  
    return lines.map((line) => {
      if (line.startsWith('Q:')) {
        return { role: 'assistant', content: line };
      } else if (line.startsWith('A:')) {
        return { role: 'human', content: line };
      } else {
        // fallback if a line doesn't match Q: or A:
        return { role: 'human', content: line };
      }
    });
  }
  
  return (
    <aside
      className={`candidate-info ${shouldShowCandidateInfo ? 'show' : ''}`}
      ref={candidateInfoRef}
    >
      {/* Scrollable wrapper inside the pinned sidebar */}
      <div className="candidate-info-scroll">
        <div className="candidate-info-header">
          <h2 style={{ fontSize: '1.2em' }}>
            {selectedCandidate ? selectedCandidate.name : 'Candidate Name'}
          </h2>
          <div>
            <button
              className="copy-link-button-candidate"
              onClick={copyLink}
              style={{ position: 'absolute', top: '10px', right: '10px' }}
            >
              Copy Link
            </button>
            <button
              className="copy-link-button-candidate"
              onClick={showResumes}
              style={{ position: 'absolute', top: '60px', right: '10px' }}
            >
              Show Resume
            </button>
          </div>
          <div className="overall-score">
            <div
              className="score-circle overall-circle"
              style={{
                backgroundColor: '#FFE4C7',
                color: '#000',
                fontWeight: 'bold',
                width: '90px',
                height: '90px',
                fontSize: '1.5em',
              }}
            >
              {overallScore}
            </div>
            <p>Overall</p>
            <div className="horizontal-circles">
              {traits.slice(0, 6).map((trait, idx) => (
                <div
                  className="circle-with-word"
                  key={idx}
                  onClick={() => handleTraitClick(trait)}
                  style={{ cursor: 'pointer' }}
                >
                  <div
                    className="score-circle small-circle"
                    style={{
                      backgroundColor: traitColors[idx % traitColors.length],
                      fontSize: '0.6em',
                      border:
                        selectedTrait === trait ? '2px solid #FF6B19' : 'none',
                    }}
                  >
                    {scores[trait] || '00'}
                  </div>
                  <p className="trait-word" style={{ fontSize: '0.7em' }}>
                    {trait.charAt(0).toUpperCase() + trait.slice(1)}
                  </p>
                </div>
              ))}
            </div>
            {selectedCandidate.skills && selectedCandidate.skills.length > 0 && (
              <div className="skills-container">
                {selectedCandidate.skills.map((skill, i) => (
                  <span key={i} className="skill-box">
                    {skill}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="candidate-info-feedback">
          <h3 style={{ fontSize: '1.5em' }}>Feedback</h3>
          <div className="feedback-content">
            {selectedTrait ? (
              <p>
                <strong>
                  {selectedTrait.charAt(0).toUpperCase() +
                    selectedTrait.slice(1)}
                  :
                </strong>{' '}
                {selectedCandidate.feedback
                  ? selectedCandidate.feedback[selectedTrait]
                  : 'No feedback available.'}
              </p>
            ) : (
              <p style={{ color: 'gray', textAlign: 'center' }}>
                Click a trait to see feedback
              </p>
            )}
          </div>
        </div>
        <hr />
        {label2 === 'resume screener' && selectedCandidate.questionAnswers && (
          <div className="candidate-info-transcript">
            <h3 style={{ fontSize: '1.5em' }}>Application Questions &amp; Answers</h3>
            {
              // 1. Parse Q/A into an array of objects with { role, content }
              parseQA(selectedCandidate.questionAnswers).map((item, idx) => (
                <div
                  key={idx}
                  className={`bubbletemplate ${item.role === 'assistant' ? 'ai' : 'human'}`}
                >
                  {item.content}
                </div>
              ))
            }
          </div>
        )}
        {label2 !== 'resume screener' && (
            <div className="candidate-info-transcript">
            <h3 style={{ fontSize: '1.5em' }}>Transcript</h3>
            {selectedCandidate.transcript ? (
              selectedCandidate.transcript
                .filter((item) => item.role === 'assistant' || item.role === 'user')
                .map((item, index) => (
                  <div
                    className={`bubbletemplate ${
                      item.role === 'assistant' ? 'ai' : 'human'
                    }`}
                    key={index}
                  >
                    {item.role === 'user' && index % 2 !== 0 && (
                      <button
                        className="play-button-speech"
                        onClick={() => handlePlayClick(index)}
                      >
                        &#9658;
                      </button>
                    )}
                    {item.content}
                  </div>
                ))
            ) : (
              <div>No transcript available.</div>
            )}
          </div>
        )}
      </div>

      {isVideoPopupVisible && (
        <div className="video-overlay" onClick={closeVideoPopup}>
          <div className="video-popup" onClick={(e) => e.stopPropagation()}>
            <div className="video-popup-content">
              <video controls autoPlay src={videoUrl} />
            </div>
          </div>
        </div>
      )}

    </aside>
  );
};

export default CandidateInfo;