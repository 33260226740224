import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom';
import PracticePage from './practice_page';
import CodingPracticePage from './coding_practice_page';
import SignInPage from './signin_page.jsx';
import Register from './register.jsx';
import BusinessRegister from './businessregister';
import HomePage from './home_page';
import ResultsPage from './results_page';
import BusinessSignInPage from './business-signin';
import UploadResume from './uploadresume';
import JobTitle from './jobtitle';
import GetReady from './getready';
import InterviewDone from './interviewdone';
import Interview from './interview';
import ApplicationQuestions from './ApplicationQuestions';
import CandidateResultPublic from './candidate-result-public';
import PaymentConfirmed from './payment_confirmed';
import SubscriptionPage from './subscription_page';
import NameEmailBusiness from './name_email';
import ApiDocumentation from './api-docs';
import { auth } from './index';
import { useAuth } from './authContext';
import { signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';
import './App.css';
import logo from './safirawhitefixedfinal.svg';
import hamburger from './hamburger.png';
import MyEditor from './interviewcoding';
import BusinessesPage from './BusinessesPage';
import CancelMembership from './cancelMembership';
import BusinessInterview from './businessinterview';
import SignInSelectionPage from './signInSelection';
import WelcomeInterview from './WelcomeInterview';
import WelcomeBusinessInterview from './welcomeBusinessInterview';
import ResetPassword from './ResetPassword'; // New import for ResetPassword component
import { app } from './index';

function App() {
  const { userLoggedIn, currentUser, setCurrentUser } = useAuth();
  const [showMenu, setShowMenu] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasUserPaid, setUserPaid] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const history = useHistory();
  let lastScrollY = window.scrollY;
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  useEffect(() => {
    const checkUserPaymentStatus = async () => {
      if (userLoggedIn && currentUser) {
        try {
          const firestore = getFirestore(app);
          const userRef = doc(firestore, 'businesses', currentUser.uid);
          const docSnap = await getDoc(userRef);
  
          if (docSnap.exists() && docSnap.data().stripePayment) {
            console.log('User has paid.');
            setUserPaid(true); // Set user payment status to true
          } else {
            console.log('User has not paid.');
            setUserPaid(false); // Set to false if not paid
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
          setShowErrorPopup(true); // Optional error popup
          setTimeout(() => setShowErrorPopup(false), 7000);
        }
      }
    };
  
    checkUserPaymentStatus();
  }, [userLoggedIn, currentUser]);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsMenuOpen(false);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        setShowMenu(false);
        setIsMenuOpen(false);
      } else {
        setShowMenu(true);
      }
      lastScrollY = window.scrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handlePracticeClick = () => {
    if (!userLoggedIn) {
      history.push('/sign-in', { state: { from: 'practice' } });
      setShowErrorPopup(true);
      setTimeout(() => {
        setShowErrorPopup(false);
      }, 7000);
    }
  };

  const handleCodingPracticeClick = () => {
    if (!userLoggedIn) {
      history.push('/sign-in', { state: { from: 'coding-interview' } });
      setShowErrorPopup(true);
      setTimeout(() => {
        setShowErrorPopup(false);
      }, 7000);
    }
  };

  const handleBusinessesClick = async () => {
    try {
        if (!userLoggedIn) {
            history.push('/business-sign-in', { state: { from: 'businesses' } });
            setShowErrorPopup(true);
            setTimeout(() => {
                setShowErrorPopup(false);
            }, 7000);
        } else {
            const firestore = getFirestore(app);
            const userRef = doc(firestore, 'businesses', currentUser.uid); // Use doc() to reference the user's document
            const docSnap = await getDoc(userRef); // Use getDoc() to retrieve the document

            if (docSnap.exists() && docSnap.data().stripePayment) {
                console.log("User has paid");
                history.push('/businesses');
            } else {
                console.log("User has not paid, redirecting to subscription page");
                history.push('/subscription-page');
            }
        }
    } catch (error) {
        console.error("Error fetching user data from Firestore:", error);
        setShowErrorPopup(true);
        setTimeout(() => {
            setShowErrorPopup(false);
        }, 7000);
    }
  };

  return (
    <Router>
      <div>
        <div className={`menu-bar ${showMenu ? '' : 'hide'}`}>
          <Link to="/">
          <img
            src={
              {
                'skytalentai.com': '/skyconsulting-logo.gif',
                'www.skytalentai.com': '/skyconsulting-logo.gif',
                'honeit.ai': '/honeit_logo-removebg-preview.png',
                'www.honeit.ai': '/honeit_logo-removebg-preview.png'
              }[window.location.hostname] || logo
            }
            alt="logo"
            className="logo"
          />
          </Link>
          <img src={hamburger} alt="Menu" className="hamburger" onClick={toggleMenu} />
          <nav>
            <ul className={`${isMenuOpen ? 'show' : 'hide'}`}>
              {/* Replaced dropdown with a single Practice link */}
              <li onClick={() => setIsMenuOpen(false)}>
                {!userLoggedIn ? (
                  <Link to="/sign-in" onClick={handlePracticeClick}>Practice</Link>
                ) : (
                  <Link to="/practice">Practice</Link>
                )}
              </li>
              {/* <li onClick={() => setIsMenuOpen(false)}>
                {!userLoggedIn && (
                  <Link to="/sign-in" onClick={handleCodingPracticeClick}>Coding Interview</Link>
                )}
                {userLoggedIn && (
                  <Link to="/coding-interview">Coding Interview</Link>
                )}
              </li> */}
              <li onClick={() => setIsMenuOpen(false)}>
                {!userLoggedIn ? (
                  <Link to="/business-sign-in">Businesses</Link>
                ) : hasUserPaid ? (
                  <Link to="/businesses">Businesses</Link>
                ) : (
                  <Link to="/subscription-page">Businesses</Link>
                )}
              </li>
              {!userLoggedIn && (
                <li>
                  <Link to="/business-sign-in" onClick={() => setIsMenuOpen(false)}>Sign In</Link>
                </li>
              )}
              {userLoggedIn && (
                <li>
                  <Link to="/" onClick={handleLogout}>Logout</Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
        <Switch>
          <Route path="/codinginterview">
            <MyEditor />
          </Route>
          <Route path="/sign-in">
            <SignInPage />
          </Route>
          <Route path="/uploadresume">
            <UploadResume />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/practice">
            <PracticePage />
          </Route>
          <Route path="/behavioral-interview">
            <PracticePage />
          </Route>
          <Route path="/businessinterview">
            <BusinessInterview />
          </Route>
          <Route path="/coding-interview">
            <CodingPracticePage />
          </Route>
          <Route path="/results-behavioral-interview">
            <ResultsPage />
          </Route>
          <Route path="/interviewdone">
            <InterviewDone />
          </Route>
          <Route path="/subscription-page">
            <SubscriptionPage />
          </Route>
          <Route path="/businesses">
            <BusinessesPage />
          </Route>
          <Route path="/jobtitle">
            <JobTitle />
          </Route>
          <Route path="/business-register">
            <BusinessRegister />
          </Route>
          <Route path="/application-questions">
            <ApplicationQuestions />
          </Route>
          <Route path="/business-sign-in">
            <BusinessSignInPage />
          </Route>
          <Route path="/api-docs">
            <ApiDocumentation />
          </Route>
          <Route path="/getready">
            <GetReady />
          </Route>
          <Route path="/payment-confirmed">
            <PaymentConfirmed />
          </Route>
          <Route path="/interview">
            <Interview />
          </Route>
          <Route path="/welcomeinterview">
            <WelcomeBusinessInterview />
          </Route>
          <Route path="/candidate-result">
            <CandidateResultPublic />
          </Route>
          <Route path="/cancel-membership">
            <CancelMembership />
          </Route>
          <Route path="/name-and-email">
            <NameEmailBusiness />
          </Route>
          <Route path="/sign-in-selection">
            <SignInSelectionPage />
          </Route>
          <Route path="/welcome">
            <WelcomeInterview />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
          <Route path="/reset-password" component={ResetPassword} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;